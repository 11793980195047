@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: linear-gradient(
    92.23deg,
    #F84D05 20.43%,
    #F8CA09 120.63%,
    #F94104 200.04%
  );
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #F8CA09 21.43%,
    #F84D05 50.63%,
    #F94104 100%,
    #ff56f6 117.04%
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #F8CA09 21.43%,
    #F84D05 50.63%,
    #F94104 100%,
    #ff56f6 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@media screen and (min-width: 320px) and (max-width: 460px) {
  #services{
    margin-top: 500px;
  }
}

@media screen and (min-width: 461px) and (max-width: 600px) {
  #services{
    margin-top: 600px;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  #services{
    margin-top: 40rem;
  }
}


@media screen and (min-width: 960px){
  #imgJulio{
      margin-bottom: -20px;
  }
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#F8CA09] to-[#F84D05] font-bold font-primary hover:from-[#F84D05] hover:to-[#F8CA09];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}


/* 960 a 1200*/
@media screen and (min-width: 960px) and (max-width: 1200px){
  
}